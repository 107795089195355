import { downloadAction, showToaster } from "./functions";

const container = document.querySelector(".container");
// const logo = document.querySelector(".logo");
const headerBtn = document.getElementById("header-btn");
const header = document.querySelector('header') as HTMLElement

// const scrollEvent = (e: Event) => {
//     console.log("scrolled ", e.target.scrollTop,  e.target.offsetHeight)
//     console.log("scroll", e.target.scrollTimeout)
// }
const options = {
    rootMargin: '0px',
    threshold: 0.40
};

const downloadBtnMap = {
    "sec-1": "#79246F",
    "sec-2": "#D93B00",
    "sec-3": "#0f6e8a",
    "sec-4": "#873512",
    "sec-5": "#871212"
}
// const downloadBtnMap = {
//   "sec-1": "#79246F",
//   "sec-2": "#5e3b15",
//   "sec-3": "#0b6393",
//   "sec-4": "#472011",
//   "sec-5": "#af2628"
// }
const root = document.documentElement;
const observer = new IntersectionObserver(entries => {
    entries.forEach(elem => {
        // console.log(elem)
      const section = elem.target;
      const videoEl = section.querySelector('video');
      if (elem.isIntersecting) {
        
        const bgColor = window.getComputedStyle(section).backgroundColor;

        if(section.id === "sec-2") {
            header.classList.add("yellow-bg");
            root.style.setProperty("--modal-tint-color", downloadBtnMap[section.id]);
        } else {
            header.classList.remove("yellow-bg");
            root.style.setProperty("--modal-tint-color", bgColor);
            
        }

        
        // console.log(bgColor)
        headerBtn.style.backgroundColor = downloadBtnMap[section.id];
        
       const timeout = setTimeout(() => {
        // header.style.backgroundColor = bgColor;
        // document.querySelector('meta[name="theme-color"]').setAttribute('content',  bgColor);
       }, 100);
    //    clearTimeout(timeout);

        window.scrollTo(0, 1); //ios 14 bottom tab creates a scroll with 100vh
    if(videoEl) {
      videoEl.play();
    }
 
    } else {
      if(videoEl) {
        videoEl.pause();
      }
    }
    });
  }, options);
  document.querySelectorAll('section').forEach(elem => observer.observe(elem));
// container.addEventListener("scroll", scrollEvent);

document.querySelectorAll('.action-link').forEach(btn => {
  btn.addEventListener("click", e => {
    e.preventDefault();
    downloadAction();
  })
});


const contactForm = document.getElementById("contact_form") as HTMLFormElement;

contactForm.addEventListener("submit", function(e) {
  e.preventDefault();

  const submitBtn = contactForm.querySelector('button');
    submitBtn.disabled = true;

    
    const formData = new FormData(contactForm);
    fetch("https://formspree.io/xaybrjld",{
      method:'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(Object.fromEntries(formData))
    })
    .then(function(response){
      if(response.ok) {
        showToaster(true, "Your message has been sent.");
        contactForm.reset();
      } else {
        showToaster(false, "Submit failed.");
      }
      submitBtn.disabled = false;
      return response;
    }).catch(function(error){
      submitBtn.disabled = false;
      showToaster(false, "Submit failed.");
    });
    // if (formData.get("name") == "error") {
    //     showToaster(false, "Submit failed.")
    // } else {
    //     showToaster(true, "Your message has been sent.")
    // }
})
